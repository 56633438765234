(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ajuntament.informacio.controller:InformacioAjuntamentCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.home.ajuntaments.ajuntament.informacio')
  .controller('InformacioAjuntamentCtrl', InformacioAjuntamentCtrl);

  function InformacioAjuntamentCtrl($state, $stateParams, $mdToast, $filter, ajuntament, towns, Ajuntaments, actionButton) {
    var vm = this;
    vm.ctrlName = 'InformacioAjuntamentCtrl';
    vm.ajuntament = ajuntament;
    vm.towns = towns;
    angular.forEach(towns, function (value) {
      if (value.id === ajuntament.idTown) vm.ajuntament.townName = value.name;
    });
    vm.edit = false;

    actionButton.dial = true;
    actionButton.buttonImage = 'images/ic_more_vert_white_48px.svg';

    actionButton.actions = [{
      buttonImage: 'images/ic_edit_black_48px.svg',
      attrClass: '',
      tooltip: 'Edita',
      execute: enableEdit
    },
      {
        buttonImage: 'images/ic_person_add_black_48px.svg',
        attrClass: '',
        tooltip: 'Nova persona',
        execute: function () {
        }
      },
      {
        buttonImage: 'images/ic_visibility_off_white_48px.svg',
        attrClass: 'md-warn',
        tooltip: 'Deshabilita',
        execute: function () {
        }
      }
    ];

    vm.updateAjuntament = updateAjuntament;

    function enableEdit() {
      vm.edit = true;
      actionButton.dial = false;
    }

    function updateAjuntament() {
      Ajuntaments.update({id: $stateParams.ajuntamentId}, vm.ajuntament, function () {
        $mdToast.show(
          $mdToast.simple()
          .textContent($filter('translate')('TOWN_HALL_SUCCESSFUL_MODIFY'))
          .position('bottom left')
          .hideDelay(3000)
        );
        $state.go($state.current, $stateParams, {reload: true});
      });
    }
  }
}());
